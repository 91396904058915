.overlay {
	position: fixed;
	background: white;
	top: 0;
	left: 0;
	width: 100%;
	height: 0%;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.35s, visibility 0.35s, height 0.35s;
	overflow: hidden;
	z-index: 90;
}
.overlay.open {
	opacity: 1;
	visibility: visible;
	height: 100%;
}

.overlay.open nav {
	padding-top: 10%;
	border: var(--border-size) solid white;
	position: relative;
	border-radius: 20px;
	height: 100%;
	background: var(--text-color);
	font-size: clamp(1rem, 4vmin, 6rem);
	font-family: 'Dela Gothic One', sans-serif;
	font-weight: 400;
	text-align: center;
}
.overlay nav ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: space-around;
	height: 100%;
	text-transform: uppercase;
}

.subitem {
	display: block;
	font-size: 11px;
	font-weight: 600;
	height: 40px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
		Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}
