@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);
.item_item__2XXZq.item_open__24Bfa {
	-webkit-animation: item_fadeInRight__pglmg 0.5s ease forwards;
	        animation: item_fadeInRight__pglmg 0.5s ease forwards;
}

.item_item__2XXZq {
	flex: 1 1;
	min-height: 50px;
	position: relative;
	opacity: 0;
}
.item_item__2XXZq a {
	display: block;
	position: relative;
	color: white;
	text-decoration: none;
	overflow: hidden;
}
.item_item__2XXZq a:hover:after,
.item_item__2XXZq a:focus:after,
.item_item__2XXZq a:active:after {
	width: 100%;
}
.item_item__2XXZq a:after {
	content: '';
	position: absolute;
	bottom: 65%;
	left: 50%;
	width: 0%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	height: var(--border-size);
	background: white;
	transition: 0.35s;
}

@-webkit-keyframes item_fadeInRight__pglmg {
	0% {
		opacity: 0;
		left: 20%;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}

@keyframes item_fadeInRight__pglmg {
	0% {
		opacity: 0;
		left: 20%;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}

.hamburger_hamburger__3lSay {
	position: fixed;
	top: 35px;
	right: 35px;
	height: 27px;
	width: 35px;
	cursor: pointer;
	z-index: 100;
	transition: opacity 0.25s ease;
	-webkit-appearance: none;
	background: transparent;
	border: 0;
}

.hamburger_hamburger__3lSay span {
	background: var(--text-color);
	border: none;
	height: 5px;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	transition: all 0.35s ease;
	cursor: pointer;
	border-radius: 10px;
}

.hamburger_hamburger__3lSay span:nth-of-type(2) {
	top: 11px;
}

.hamburger_hamburger__3lSay span:nth-of-type(3) {
	top: 22px;
}

.hamburger_hamburger__3lSay:hover {
	opacity: 0.7;
}

.hamburger_active__3pmiE > span {
	background: #fff;
}

.hamburger_active__3pmiE .hamburger_top__3UZds {
	-webkit-transform: translateY(11px) translateX(0) rotate(45deg);
	        transform: translateY(11px) translateX(0) rotate(45deg);
}
.hamburger_active__3pmiE .hamburger_middle__3Lwvk {
	opacity: 0;
}
.hamburger_active__3pmiE .hamburger_bottom__PW88x {
	-webkit-transform: translateY(-11px) translateX(0) rotate(-45deg);
	        transform: translateY(-11px) translateX(0) rotate(-45deg);
}

.menu_overlay__2Qvjf {
	position: fixed;
	background: white;
	top: 0;
	left: 0;
	width: 100%;
	height: 0%;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.35s, visibility 0.35s, height 0.35s;
	overflow: hidden;
	z-index: 90;
}
.menu_overlay__2Qvjf.menu_open__1vOT2 {
	opacity: 1;
	visibility: visible;
	height: 100%;
}

.menu_overlay__2Qvjf.menu_open__1vOT2 nav {
	padding-top: 10%;
	border: var(--border-size) solid white;
	position: relative;
	border-radius: 20px;
	height: 100%;
	background: var(--text-color);
	font-size: clamp(1rem, 4vmin, 6rem);
	font-family: 'Dela Gothic One', sans-serif;
	font-weight: 400;
	text-align: center;
}
.menu_overlay__2Qvjf nav ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: space-around;
	height: 100%;
	text-transform: uppercase;
}

.menu_subitem__1vRFq {
	display: block;
	font-size: 11px;
	font-weight: 600;
	height: 40px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
		Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

.app_app__M9MMq {
	height: 100%;
	border: var(--border-size) solid var(--text-color);
	display: grid;
	place-items: center;
	place-content: center;
	grid-template-areas: 'body';
	overflow: hidden;
	font-family: 'Dela Gothic One', sans-serif;
	background-color: var(--bg-color);
	border-radius: 20px;
}

.app_app__M9MMq::before {
	--size: 150vmax;
	grid-area: body;
	content: '';
	inline-size: var(--size);
	block-size: var(--size);
	background-image: url(/static/media/foot-pattern.3777b270.svg);
	background-size: var(--bg-size);
	background-repeat: repeat;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	opacity: 0.25;
	-webkit-animation: app_bg__Q_EV- 6s linear infinite;
	        animation: app_bg__Q_EV- 6s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
	.app_app__M9MMq::before {
		-webkit-animation-duration: 0s;
		        animation-duration: 0s;
	}
}

@-webkit-keyframes app_bg__Q_EV- {
	to {
		background-position: 0 calc(var(--bg-size) * -1);
	}
}

@keyframes app_bg__Q_EV- {
	to {
		background-position: 0 calc(var(--bg-size) * -1);
	}
}

* {
	box-sizing: border-box;
}

html,
body,
#root {
	height: 100%;
}

h1 {
	font-size: inherit;
	font-weight: inherit;
}

body {
	--text-color: hsl(0 95% 60%);
	--bg-color: hsl(0 0% 100%);
	--bg-size: 200px;
	--border-size: 10px;
	margin: 0;
	background-color: var(--text-color);
}

.text {
	margin: 10px;
	grid-area: body;
	position: relative;
	display: flex;
	flex-direction: column;
	font-size: clamp(1rem, 7vmin, 6rem);
}

.heading span {
	display: block;
	text-transform: uppercase;
}

.heading span.filled {
	color: var(--text-color);
}

.heading span:not(.filled) {
	--stroke: min(0.35vmin, 4px) var(--text-color);
	color: var(--bg-color);
	-webkit-text-stroke: var(--stroke);
	text-stroke: var(--stroke);
}

.subheading {
	position: relative;
	-webkit-margin-before: 1rem;
	        margin-block-start: 1rem;
	-webkit-margin-start: auto;
	        margin-inline-start: auto;
	font-size: 0.428em;
	color: var(--text-color);
}

