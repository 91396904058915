@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');

* {
	box-sizing: border-box;
}

html,
body,
#root {
	height: 100%;
}

h1 {
	font-size: inherit;
	font-weight: inherit;
}

body {
	--text-color: hsl(0 95% 60%);
	--bg-color: hsl(0 0% 100%);
	--bg-size: 200px;
	--border-size: 10px;
	margin: 0;
	background-color: var(--text-color);
}

.text {
	margin: 10px;
	grid-area: body;
	position: relative;
	display: flex;
	flex-direction: column;
	font-size: clamp(1rem, 7vmin, 6rem);
}

.heading span {
	display: block;
	text-transform: uppercase;
}

.heading span.filled {
	color: var(--text-color);
}

.heading span:not(.filled) {
	--stroke: min(0.35vmin, 4px) var(--text-color);
	color: var(--bg-color);
	-webkit-text-stroke: var(--stroke);
	text-stroke: var(--stroke);
}

.subheading {
	position: relative;
	margin-block-start: 1rem;
	margin-inline-start: auto;
	font-size: 0.428em;
	color: var(--text-color);
}
